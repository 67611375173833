@import './shared/styles/_vuetify-overrides.scss';































































































































































// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern
@import './styles';

// poppins font
@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Black.ttf') format('truetype');
	font-weight: 900;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Medium.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Light.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
	font-weight: 100;
}

@font-face {
	font-family: 'Poppins-Italic';
	src: url('./fonts/Poppins-Italic.ttf') format('truetype');
	font-weight: 400;
}

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/design.

html,
body,
.v-application {
	height: 100%;
	overflow: hidden !important;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
}

body {
	::-webkit-scrollbar {
		width: 15px;
		height: 15px;
	}

	::-webkit-scrollbar-track {
		background: none;
	}

	::-webkit-scrollbar-thumb {
		background: #cfd8dc;
		background-clip: padding-box;
		border: 4px solid rgba(0, 0, 0, 0);
		border-radius: 99999px;
	}
}

.v-select-list {
	margin-top: 10px !important;
	background-color: orange;

	::-webkit-scrollbar-track {
		background: #fff !important;
	}
}

#app {
	height: 100%;
	overflow: hidden;
	@extend %typography-small;
}

// ===
// Base element styles
// ===

a,
a:visited {
	color: #39a275;
}

h1 {
	@extend %typography-xxlarge;
}

h2 {
	@extend %typography-xlarge;
}

h3 {
	@extend %typography-large;
}

h4 {
	@extend %typography-medium;
}

h5,
h6 {
	@extend %typography-small;
}

// override vuetify default style globally

.v-application .text-h1.text-h1,
.v-application .text-h2.text-h2,
.v-application .text-h3.text-h3,
.v-application .text-h4.text-h4,
.v-application .text-h5.text-h5,
.v-application .text-h6.text-h6,
.v-application .text-subtitle-1.text-subtitle-1,
.v-application .text-subtitle-2.text-subtitle-2,
.v-application .text-body-1.text-body-1,
.v-application .text-body-2.text-body-2,
.v-application .text-button.text-button,
.v-application .text-caption.text-caption,
.v-application .text-overline.text-overline {
	font-family: 'Poppins', sans-serif !important;
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
.v-slide-group__next,
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
.v-slide-group__prev {
	display: none !important;
}

.v-tooltip__content {
	width: 300px;
}

.v-radio {
	.v-label {
		font-size: 0.875rem !important;
	}
}

.v-input--checkbox {
	.v-label {
		font-size: 0.875rem !important;
	}
}

// Global Card Styles
.cassie_card {
	border-left: 5px left grey;
}

// Tab background none for consent banner page
.theme--light.v-tabs-items {
	width: 100%;
	background-color: rgba(0, 0, 0, 0) !important;
}

// ===
// Vendor
// ===

#nprogress .bar {
	background: #1976d2;
}
